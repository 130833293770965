<template>
    <div v-if="loaded">
        <div class="d-flex flex-wrap mb-4">
            <switches
                :value="edit"
                :emit-on-mount="false"
                @input="toggleEdit()"
                theme="bootstrap"
                :color="colorSwitch(edit)"
                title="Modifer cette commande"
            ></switches>
            <div v-if="order.employee_id > 0" class="text-info ml-4">
                Préparateur: {{ get_employee_username( order.employee_id) }}
            </div>

            <div class="ml-md-auto d-flex">
                <div>{{ customer.mapotempo.tour }}</div>
            </div>
        </div>
        <div>
            <table class="table table-borderless">
                <thead>
                    <th class="pointer" @click="$modal.show('customer_modal')">
                        {{ customer.id }}
                        {{ customer.last_name }}
                        {{ customer.first_name }}
                        <div
                            class="text-secondary"
                            v-if="order.current_state == 3"
                        >
                            Épicerie
                        </div>
                        <div
                            class="text-secondary"
                            v-if="order.current_state == 14"
                        >
                            Associée
                        </div>
                    </th>
                    <th>unité</th>
                    <th>Qté</th>
                    <th></th>
                </thead>
                <tr
                    v-for="order_product in order_products"
                    :key="order_product.id"
                >
                    <td>
                        <product-info
                            :product="find_product(order_product.id_product)"
                        ></product-info>
                    </td>
                    <td>
                        <product-info
                            :product="find_product(order_product.id_product)"
                            attribute="unity"
                        ></product-info>
                    </td>
                    <td
                        class="pointer"
                        @click="show_distributed_quantity_modal(order_product)"
                    >
                        <div
                            :class="{
                                'line-through':
                                    order_product.distributed_quantity !== null,
                            }"
                        >
                            {{ order_product.quantity }}
                        </div>
                        <div class="text-warning">
                            {{ order_product.distributed_quantity }}
                        </div>
                    </td>
                    <td>
                        <i
                            class="fas fa-exclamation-triangle text-warning"
                            v-if="order_product.distributed_quantity !== null"
                        ></i>
                        <i
                            class="fas fa-check-circle"
                            :class="get_class(order_product.ready)"
                            @click="toggle_ready(order_product)"
                            v-else
                        ></i>
                    </td>
                </tr>
            </table>

            <div class="text-center" v-if="finished">
                Préparation terminée
                <h4 class="text-warning" v-if="lack">Des articles manque!</h4>
                <h4 class="text-success" v-else>La commande est complète!</h4>
            </div>
        </div>

        <modal
            name="distributed_quantity_modal"
            width="200"
            height="auto"
            :scrollable="true"
        >
            <div>
                <div class="card-body" v-if="order_product_id">
                    <input
                        type="number"
                        step="0.01"
                        v-model="distributed_quantity"
                        class="full-width"
                        autofocus
                    />
                </div>
                <div
                    class="card-footer bg-primary text-center pointer"
                    @click="update_disctributed_quantity()"
                >
                    Valider
                </div>
            </div>
        </modal>

        <modal
            name="customer_modal"
            :width="windowWidth > 420 ? '400' : '100%'"
            height="auto"
            :scrollable="true"
        >
            <div class="p-4">
                <h5>
                    {{ customer.id }}
                    {{ customer.last_name }}
                    {{ customer.first_name }}
                </h5>

                <div class="text-secondary mb-4">
                    <span v-if="order.current_state == 3">Épicerie</span>
                    <span v-if="order.current_state == 14">Associée</span>
                </div>

                <div class="mb-2">{{ customer.phone }}</div>
                <div class="mb-4">{{ customer.email }}</div>

                <div class="text-secondary">
                    {{ customer.mapotempo && customer.mapotempo.tour }}
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import order_products from "../mixins/order_products";
export default {
    mixins: [order_products],
    data() {
        return {
            order_id: this.$route.params.id,
            order: {},
        };
    },
    computed: {
        finished() {
            if (this.order_products.length == 0) return false;
            for (const order_product of this.order_products) {
                if (
                    !order_product.ready &&
                    order_product.distributed_quantity === null
                ) {
                    return false;
                }
            }
            return true;
        },
        lack() {
            for (const order_product of this.order_products) {
                if (
                    !order_product.ready &&
                    order_product.distributed_quantity < order_product.quantity
                ) {
                    return true;
                }
            }
            return false;
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loaded = false;
            axios.get("/orders/" + this.order_id).then(({ data }) => {
                console.log(data);
                if (data.customer) this.customer = data.customer;
                if (data.order_products)
                    this.order_products = data.order_products;
                if (data.products) this.products = data.products;
                if (data.order) {
                    this.order = data.order;
                    this.edit = data.order.employee_id == this.authUser.id;
                }
                this.loaded = true;
            });
        },
        toggleEdit() {
            this.edit = !this.edit;
            let value = this.edit ? this.authUser.id : null;
            let order_id = this.order.id;
            axios
                .patch(`/orders/${order_id}`, {
                    employee_id: value,
                })
                .then(({ data }) => {
                    // data is the order object
                    this.order.employee_id = data.employee_id;
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response) console.log(error.response.data);
                });
        },
    },
};
</script>

<style>
</style>