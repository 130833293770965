import { mapGetters } from "vuex";

export default {
  data() {
    return {
      pathname: window.location.pathname,
      windowWidth: window.innerWidth,
      small: window.innerWidth < 768,
      development: process.env.NODE_ENV === 'development',
      pathname: window.location.pathname,
    };
  },
  computed: {
    authUser() {
      return this.$store.state.Auth.authUser;
    },
    tour() {
      return this.$store.state.Orders.tour;
    },
    tours() {
      return this.$store.state.Orders.tours;
    },
    tourOptions() {
      if (this.tour)
        return this.tours.filter((elem) => elem.id !== this.tour);
      return this.tours;
    },
    employees() {
      return this.$store.state.Orders.employees;
    },
    // getters from vuex Auth
    ...mapGetters(["authRoleId", "signedIn", "authUserId", "isAdmin"]),
  },
  methods: {
    redirectBack() {
      const path = window.sessionStorage.getItem("url.next");
      if (path) window.location.replace(path);
      else window.loacation.replace('/')
    },
    dateDisplay(date) {
      if (date) return moment(date, 'YYYY-MM-DD h:mm:ss').format('DD.MM.YYYY');
    },
    timeDisplay(date) {
      if (date) return moment(date, 'YYYY-MM-DD h:mm:ss').format('hh:mm:ss');
    },
    priceDisplay(number) {
      if (number) return number.toFixed(2);
    },
    // display decimals  0.50001 => 0.5
    floatDisplay(number){
      if (number) {
        var string = number.toFixed(2);
        return parseFloat(string);   
      }
    },
    toFormData: function (obj) {
      var form_data = new FormData();
      for (var key in obj) {
        if (obj[key] !== null && key !== 'image') form_data.append(key, obj[key]);
      }
      return form_data;
    },
    nullData: function (obj) {
      for (var key in obj) {
        obj[key] = null;
      }
      return obj;
    },
    ucfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    colorSwitch(active) {
      return active ? 'success' : 'default'
    },
    toggleActive(url, active) {
      axios.patch(url, {
        'active': active
      });
    },
    toggleAvailable(url, available) {
      axios.patch(url, {
        'available': available
      });
    },
    arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele != value;
      });
    },
    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key))
          return false;
      }
      return true;
    },
    checkLogin(path) {
      if (this.signedIn) window.location.href = path;
      else this.$modal.show('login_modal');
    },
    getGroupName(id) {
      const elem = this.groups.find(elem => elem.id == id);
      if (elem) return elem.name;
    },
    getCategoryName(id) {
      var elem = this.categories.find(elem => elem.id == id);
      if (elem) return elem.name;
    },
    redirect(redirectUrl) {
      window.location.replace(redirectUrl);
    },
    find_product(id) {
      return this.products.find(elem => elem.id == id);
    },
    get_employee_username(id) {
      if (!id) return null;
      // console.log(this.employees);
      let employee = this.employees.find((elem) => elem.id == id);
      if (employee) return employee.username;
    },
  }
}