import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "./icons"; // auto register all svg icons

Vue.config.productionTip = false

require("./bootstrap");

import "@/styles/index.scss"; // global css

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
const files = require.context("./", true, /\.vue$/i);
files.keys().map((key) =>
    Vue.component(
        key
            .split("/")
            .pop()
            .split(".")[0],
        files(key).default
    )
);



import helper from "./mixins/helper";
Vue.mixin(helper);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
