<template></template>

<script>
export default {
  beforeCreate() {
    if (navigator.onLine && this.$store.getters.signedIn) {
      if (!window.sessionStorage.getItem("employeesUpdated"))
        this.$store.dispatch("fetchEmployees");

      if (!window.sessionStorage.getItem("douzainesUpdated"))
        this.$store.dispatch("fetchDouzaines");

      if (!window.sessionStorage.getItem("toursUpdated"))
        this.$store.dispatch("fetchTours").then((result) => {
          console.log("tours", result);
          if (!this.tour) {
            let tour = result[0];
            this.$store.commit("tourMutation", tour);
          }
        });
    }
  },
};
</script>

<style>
</style>