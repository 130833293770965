<template>
  <modal name="add_product_in_order" width="300" height="auto" :scrollable="true">
    <!-- A row of columns that will contain the search elements -->
    <div class="row">
      <!-- 12-width column for the auto-complete search component -->
      <div class="col-12">
        <!-- Search input field bound to the 'title' variable to store the user input value -->
        <input type="search" v-model="title" class="form-control"
               v-on:keyup="auto_complete_title(); loading = true;" placeholder="Recherchez un produit.." />


        <!-- Template to display the selected product's information if the 'selected_product' variable is not empty -->
        <template v-if="Object.keys(selected_product).length > 0">
          <!-- Component that displays the details of the selected product -->
          <product-info-search :product="selected_product"></product-info-search>

          <!-- If orders more than 1 -->
          <template v-if="orders.length > 1">
            <div id="order-select">
              <label for="order-select">Sélectionner un numéro de commande :</label>
              <select v-model="selectedOrderId">
                <option value="" disabled>Veuillez sélectionner une commande</option>
                <option v-for="order in orders" :value="order.id">{{ order.id }}</option>
              </select>
            </div>
          </template>

          <!-- Valid button to add the selected product to the order -->
          <div class="card-footer bg-primary text-center pointer" @click="insert_product_in_order">
            Valider
          </div>
        </template>

        <!-- Template to display search results if the 'results_title' variable is not empty -->
        <template v-else-if="results_title.length">
          <!-- List group that displays the search results with the autocomplete class -->
          <ul class="list-group autocomplete" v-if="!loading">
            <!-- Individual list-group item element for each result -->
            <li class="list-group-item" v-for="result in results_title" :key="result.title">
              <!-- Link that displays the details of the selected product with the 'product-info' component when the user clicks on a result -->
              <a href="#" class="color_none" @click="add_product(result)"><product-info-search
                  :product="result"></product-info-search></a>
            </li>
          </ul>
          <!-- Spinner to indicate loading when searching  - hidden by default -->
          <ul class="list-group autocomplete" v-else>
            <li class="list-group-item">
              <div class="spinner-border" v-if="loading">
                <span class="sr-only">Loading...</span>
              </div>
            </li>
          </ul>
        </template>
      </div>

    </div>
  </modal>
</template>

<script>
import { debounce } from 'lodash';
export default {
  props: ['orders'],
  data() {
    return {
      loading: false,
      title: '',
      results_title: [],
      selected_product: {},
      selectedOrderId: this.orders[0].id,
    };
  },
  computed: {
  },
  created() {
  },
  watch: {
    title(val) {
      if (val == '')
          // Remove selected_product if the search input becomes empty.
        this.selected_product = {};
    }
  },
  methods: {
    auto_complete_title: debounce(async function () {
      const response = await axios.post('products/search', { search: this.title });
      this.results_title = response.data;
      // IF new query, remove selected_product.
      this.selected_product = {};
      this.loading = false;
    }, 500),
    add_product(product) {
      this.selected_product = product;
      this.title = product.name;
    },
    async insert_product_in_order() {
      const content = {
        'id_order' : this.orders.length > 1 ? this.selectedOrderId : this.orders[0].id,
        'product' : this.selected_product,
      };
      const response = await axios.post('/order/product/insert', content);
      // Refresh data in parent component
      this.$emit('fetch');
    },
  }
}
</script>

<style scoped></style>