const module = {
  state: {
    tour: localStorage.getItem("tour"),
    tours: JSON.parse(window.localStorage.getItem("tours")) || [],
    employees: JSON.parse(window.localStorage.getItem("employees")) || [],
    douzaines: JSON.parse(window.localStorage.getItem("douzaines")) || []
  },
  mutations: {
    tourMutation(state, payload) {
      state.tour = payload;
      window.localStorage.setItem("tour", payload);
    },
    toursMutation(state, payload) {
      state.tours = payload;
      window.localStorage.setItem("tours", JSON.stringify(payload));
      window.sessionStorage.setItem("toursUpdated", Date.now());
    },
    employeesMutation(state, payload) {
      state.employees = payload;
      window.localStorage.setItem("employees", JSON.stringify(payload));
      window.sessionStorage.setItem("employeesUpdated", Date.now());
    },
    douzainesMutation(state, payload) {
      state.douzaines = payload; 
      window.localStorage.setItem("douzaines", JSON.stringify(payload)); 
      window.sessionStorage.setItem("douzainesUpdated", Date.now()); 
    }
  },
  actions: {
    async fetchTours({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get("/mapotempo/tours")
          .then(({ data }) => {
            commit("toursMutation", data);
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    async fetchEmployees({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get("/employees/list")
          .then(({ data }) => {
            commit("employeesMutation", data);
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    async fetchDouzaines({ commit }){
      return new Promise((resolve, reject) =>{
        axios.get("/douzaines/list")
        .then(({ data })=>{
          commit("douzainesMutation", data); 
          resolve(data); 
        })
        .catch(error => {
          reject(error);
        });
      })
    }
  },
  getters: {
  }
}


export default module