<template>
    <download-csv :labels="header" :data="rows" delimiter=";" name="vitalog.csv" class="btn btn-primary" v-if="loaded">
        Export CSV        
    </download-csv>
</template>

<script>
export default {
    data() {
        return {
            loaded : false,
            header: [],
            rows: [],
        };
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loaded = false; 
            axios.get("/export").then(({ data }) => {
                this.header = data.header;
                this.rows = data.rows;
                this.loaded = true; 
            });
        },
    },
};
</script>

<style>
</style>