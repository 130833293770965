<template>
  <div v-if="product">
    <div v-if="attribute">{{ product[attribute] }}</div>
      <div>
        <span class="product_name">{{ product.name }}</span>
        <div class="text-secondary">
          {{ product.id }}
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ["product", "attribute"],
};
</script>

<style>
</style>