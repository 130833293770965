<template>
  <div>
    <div class="text-right mb-4">
      <select :value="tour" @input="selectTour">
        <option v-for="item in tourOptions" :key="item" :value="item">
          {{ item }}
        </option>
      </select>
    </div>
    <table class="table">
      <thead>
        <th>Client</th>
        <th>Commandes</th>
        <th>Ordre</th>
        <th>Heures</th>
        <th>Actions</th>
      </thead>
      <tbody>
      <tr v-for="coupleAddressUser in couplesAddressUser" :key="coupleAddressUser.id_delivery_address">
        <td class="pointer" @click="$modal.show('customer_modal', { coupleAddressUser: coupleAddressUser })">
            {{ coupleAddressUser.customer.id }} {{ coupleAddressUser.customer.last_name }}
            {{ coupleAddressUser.customer.first_name }}
          </td>
          <td>
            <ul class="list-unstyled">
              <!-- orders douzaines-->
<!--              <li v-for="order in coupleAddressUser.orders_d" :key="order.id">-->
<!--                <span class="mr-4">{{ order.reference }}</span>-->
<!--                <span>-->
<!--                  <i class="fas fa-check-circle text-success" v-if="order.ready"></i>-->
<!--                </span>-->
<!--              </li>-->
              <!-- orders -->
              <li v-for="order in coupleAddressUser.orders" :key="order.id">
                <span class="mr-4">{{ order.reference }}</span>
                <span v-if="order.finished">
                  <i class="fas fa-exclamation-triangle text-warning" v-if="order.lack"></i>
                  <i class="fas fa-check-circle text-success" v-else></i>
                </span>
                <span v-else>
                 <!--<i class="fas fa-clock text-waiting" v-if="order.started"></i>
                  <i class="fas fa-clock text-waiting"></i>
                {{ order.started }}-->
               </span>
             </li>
           </ul>
         </td>
         <td>{{ coupleAddressUser.mapotempo[0].order }}</td>
         <td>{{ coupleAddressUser.mapotempo[0].hour }}</td>
         <td>
           <i class="fas fa-shopping-basket text-primary" @click="redirect(customerId = coupleAddressUser.id_customer, orderIds = coupleAddressUser.orderIds)"></i>
         </td>
       </tr>
     </tbody>
   </table>

   <modal name="customer_modal" :width="windowWidth > 450 ? '450' : '100%'" height="auto" :scrollable="true"
     @before-open="(event)=>{ coupleAddressUser=event.params.coupleAddressUser}">
     <div
       class="p-4"
       v-if="coupleAddressUser"
     >
       <h5>
         {{ coupleAddressUser.customer.id }}
         {{ coupleAddressUser.customer.last_name }}
         {{ coupleAddressUser.customer.first_name }}
       </h5>

       <div class="mb-2">{{ coupleAddressUser.customer.phone }}</div>
       <div class="mb-4">{{ coupleAddressUser.customer.email }}</div>

       <div class="text-secondary">
         {{ coupleAddressUser.mapotempo[0] && coupleAddressUser.mapotempo[0].tour }}
       </div>
     </div>
   </modal>
 </div>
</template>

<script>
export default {
 data() {
   return {
     couplesAddressUser: [],
     coupleAddressUser: null
   };
 },
 created() {
   this.fetch();
 },
 methods: {
   fetch() {
     let tour = this.tour;
     axios
       .get(`/tour/${tour}/customers`)
       .then(({ data }) => {
         console.log(data);
         if (data.couplesAddressUser) {
           data.couplesAddressUser.sort(function (a, b) {
             return a.order - b.order;
           });
           this.couplesAddressUser = data.couplesAddressUser;
         }
       })
       .catch((error) => {
         console.log(error);
       });
   },
   redirect(customerId,orderIds) {
     this.$router.push({
       name: "CustomerShow",
       params: { customer_id:customerId, order_ids:orderIds}
     });
     console.log(customerId,orderIds)
   },
   selectTour(e) {
     let tour = e.target.value;
     this.$store.commit("tourMutation", tour);
     this.fetch();
   },
 },
};
</script>

<style>
</style>