<template>
  <input type="number" class="input-xs" v-model="ratio" @change="update()">
</template>

<script>
export default {
  props: ['is_expired', 'douzaine_id', 'size_id', 'purchase_ratio'],
  data() {
    return {
      ratio: this.purchase_ratio ? this.purchase_ratio.ratio : "",
    };
  },
  methods: {
    update() {      
      axios.post("/purchase-ratios", {
        douzaine_id: this.douzaine_id,
        size_id: this.size_id,
        ratio: this.ratio
      })
        .then(({ data }) => {
          console.log(data);
          this.$emit('updated', data); 
        })
        .catch((error) => {
          console.log(error.response.data); 
          // flash(error.response.data, 'danger');
        });
    }    
  }
}
</script>

<style>

</style>