<template>
  <div>
    <span
      v-if="is_expired"
      :style="{ color: color }"
    >{{ quantity }}</span>
    <template v-else>
      <input
        type="number"
        step="0.01"
        class="input-xs"
        v-model="quantity"
        @change="update()"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: ['is_expired', 'douzaine_id', 'size_id', 'product_id', 'douzaine_product'],
  data() {
    return {
      quantity: this.douzaine_product ? this.douzaine_product.quantity : "",
    };
  },
  methods: {
    update() {
      axios.post("/douzaine-products", {
        douzaine_id: this.douzaine_id,
        size_id: this.size_id,
        product_id: this.product_id,
        quantity: this.quantity
      })
        .then(({ data }) => {
          console.log(data);
          this.$emit('updated', data); 
        })
        .catch((error) => {
          // flash(error.response.data, 'danger');
        });
    }
  }
}
</script>

<style>
</style>