<template>
  <div v-if="product">
    <div v-if="attribute">{{ product[attribute] }}</div>

    <div class="d-flex flex-wrap" v-else>
      <img class="thumb mr-md-4" :src="product.thumb_asset"  />
      <div>
        <span class="product_name">{{ product.name }}</span>
        <div class="text-secondary">
          {{ product.provider && product.provider.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product", "attribute"],
};
</script>

<style>
</style>