<template>
  <a :class="link_class" @click="logout">
    <svg-icon icon-class="log-out" style="color: #498de5; width:20px; height:16px" class></svg-icon>
  </a>
</template>

<script>
export default {
  name: "LoginForm",
  props:['link_class'],
  data () {
    return {
    }
  },
  methods: {
    logout(){
      this.$store.dispatch('logoutAction');
      this.$router.push('/');
    }
  }
}
</script>
