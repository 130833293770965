<template>
  <div>
    <table class="table">
      <thead>
      <th>Nom de la tournée</th>
      <th>Nb de commandes</th>
      <th>Actions
        <div class="spinner-border" style="color: blue;" v-if="!loaded_mail">
          <span class="sr-only">Envoi MAIL en cours..</span>
        </div>
      </th>

      </thead>
      <tbody v-if="loaded">
      <tr v-for="item in tourOptions" :key="item">
        <td>
          {{ item }}
        </td>
        <td>{{ get_nb_orders(item).count }} <i class="fas fa-check-circle text-success" v-if="get_nb_orders(item).ready"
                                               title="Tournée prête."></i></td>
        <td v-if="!get_nb_orders(item).emailed_at"><i class="fas fa-envelope"
                                                      @click="send_tour_to_customers(item, get_nb_orders(item).ready)"></i></td>
        <td v-else> Envoyé le {{ new Date(get_nb_orders(item).emailed_at).toLocaleString('fr-FR', {
          hour: 'numeric',
          minute: 'numeric', second: 'numeric', year: 'numeric', month: 'numeric', day: 'numeric'
        }) }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mapotempos: [],
      loaded : false,
      loaded_mail: true,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      axios
          .get(`/orders/tours/recap`)
          .then(({ data }) => {
            this.mapotempos = data.mapotempos;
            this.loaded = true;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    get_nb_orders(tour) {
      let count = 0;
      let ready = true
      let mapotempos_filtered = this.mapotempos.filter(x => x.tour === tour);
      let emailed_at = '';
      if (mapotempos_filtered.length && mapotempos_filtered[0].emailed_at)
        emailed_at = mapotempos_filtered[0].emailed_at;

      const ordersProducts = mapotempos_filtered.map(item => {
        // Utilisez "Object.values()" pour obtenir un tableau des valeurs de l'objet "orders"
        const ordersArray = Object.values(item.orders);
        // Utilisez reduce () sur le tableau ci-dessus pour concaténer tous les "order_products" en un seul tableau
        return ordersArray.reduce((result, order) => {
          return [...result, ...order.order_products];
        }, []);
      });
      if (ordersProducts.length)
        ordersProducts.forEach(orderProducts => {
          count += 1
          orderProducts.forEach(orderProduct => {
            if (!orderProduct.ready)
              ready = false;
          });
        });

      else
        ready = false;
      return { count: count, ready: ready, emailed_at: emailed_at };
    },
    async send_tour_to_customers(tour, ready) {
      this.loaded_mail = false;
      if (!ready) {
        const confirmMessage = 'Etes-vous sûr de vouloir envoyer les bons de livraisons, certaines commandes ne sont pas finalisées.';
        if (!confirm(confirmMessage)) {
          return;
        }
      }
      const response = await axios.post('orders/tours/recap/send', { tour: tour }, { responseType: 'blob' });
      if (response.status == 200)
        alert('Email envoyé avec succès.');
      else
        alert("Une erreur est survenue lors de l'envoi des mails. Merci de réessayez.");
      this.loaded_mail = true;
      this.fetch();
        }
  },
};
</script>

<style></style>