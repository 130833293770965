<template>
  <div v-if="loaded">
    <!-- the employee who prepare the order -->
    <div class="d-flex flex-wrap mb-4">
      <switches :value="edit" :emit-on-mount="false" @input="toggleEdit()" theme="bootstrap" :color="colorSwitch(edit)"
                title="Modifer cette commande"></switches>

      <div v-if="employee_id > 0" class="text-info ml-4">
        Préparateur: {{ get_employee_username(employee_id) }}
      </div>
      <div class="ml-md-auto d-flex">
        <i class="fas fa-plus-circle fa-2x" style="color: green;" @click="$modal.show('add_product_in_order')"></i>
      </div>
    </div>

    <!-- customer info -->
    <div class="d-flex flex-wrap mb-4">
      <div class="pointer" @click="$modal.show('customer_modal')">
        {{ customer.id }}
        {{ customer.last_name }}
        {{ customer.first_name }}
      </div>

      <div class="ml-md-auto d-flex">
        <div>{{ customer.mapotempo && customer.mapotempo.tour }}</div>
      </div>
    </div>

    <!-- orders for the customer -->
    <div v-if="orders.length > 0">
      <table class="table">
        <thead>
          <th>
            <div class="text-secondary d-flex flex-wrap">
              <!-- orders douzaines-->
              <div v-if="orders_d.length > 0" class="mr-md-4">
                Douzaine
              </div>
              <!-- orders -->
              <div v-for="order in orders" :key="order.id" class="mr-2">
                {{ order.id }}
                <span v-if="order.current_state == 3">Épicerie</span>
                <span v-if="order.current_state == 14">Associée</span>
              </div>
            </div>
          </th>
          <th>unité</th>
          <th>Qté</th>
          <th>Préparateur</th>
          <th></th>
        </thead>

        <!-- orders douzaines-->
<!--        <tr v-for="order in orders_d" :key="order.id" class="mr-4">-->
<!--          <td>-->
<!--            <div class="d-flex flex-wrap">-->
<!--              <img class="thumb" :src="dz_thumb_asset(order.product)"/>-->
<!--              <div class="ml-md-4">-->
<!--                {{ order.id }}-->
<!--                {{ order.product.name }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </td>-->

<!--          <td>{{ order.size.name }}</td>-->
<!--          <td></td>-->
<!--          <td>-->
<!--            <i class="fas fa-check-circle" :class="get_class(order.ready)" @click="toggle_ready_d(order)"></i>-->
<!--          </td>-->
<!--        </tr>-->

        <!-- orders-->
        <tr v-for="order_product in order_products" :key="order_product.current_state + '_' + order_product.id">
          <td>
            <product-info :product="find_product(order_product.id_product)"></product-info>
          </td>
          <td>
            <product-info :product="find_product(order_product.id_product)" attribute="unit"></product-info>
          </td>
          <td class="pointer" @click="show_distributed_quantity_modal(order_product)">
            <div :class="{
                 'line-through':
                  order_product.distributed_quantity !== null,
                 }">
              {{ order_product.quantity }}
            </div>
            <div class="text-warning">
              {{ order_product.distributed_quantity }}
            </div>
          </td>
          <td>{{ order_product.employee ? order_product.employee.username : '' }}</td>
          <td>
            <i class="fas fa-exclamation-triangle text-warning" v-if="order_product.distributed_quantity !== null"></i>
            <i class="fas fa-check-circle" :class="get_class(order_product.ready)" @click="toggle_ready(order_product)"
               v-else></i>
          </td>
        </tr>
        <tr>
          <customer-show-note :orders="orders" />
        </tr>
      </table>

      <div class="text-center" v-if="finished">
        Préparation terminée
        <h4 class="text-warning" v-if="lack">Des articles manque!</h4>
        <h4 class="text-success" v-else>La commande est complète!</h4>
      </div>
    </div>

    <modal name="distributed_quantity_modal" width="200" height="auto" :scrollable="true">
      <div>
        <div class="card-body" v-if="order_product_edit">
          <input type="number" v-model="distributed_quantity" class="full-width" autofocus/>
        </div>
        <div class="card-footer bg-primary text-center pointer" @click="update_disctributed_quantity()">
          Valider
        </div>
      </div>
    </modal>
    <modal name="order_finished" width="400" height="auto" :scrollable="true">
      <div>
        <div class="card-body">
          Préparation terminée
        </div>
  </div>
    </modal>
      <ModalAddProductInOrder :orders="orders" @fetch="fetch" />
    <modal name="customer_modal" :width="windowWidth > 420 ? '400' : '100%'" height="auto" :scrollable="true">
      <div class="p-4">
        <h5>
          {{ customer.id }}
          {{ customer.last_name }}
          {{ customer.first_name }}
        </h5>

        <div class="text-secondary mb-4">
          <span v-if="states.includes(3)">Épicerie</span>
          <span v-if="states.includes(14)">Associée</span>
        </div>

        <div class="mb-2">{{ customer.phone }}</div>
        <div class="mb-4">{{ customer.email }}</div>

        <div class="text-secondary">
          {{ customer.mapotempo && customer.mapotempo.tour }}
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
let finished_modal_display = true;
import order_products from "../mixins/order_products";
export default {
  mixins: [order_products],
  data() {
    return {
      customer_id: this.$route.params.customer_id,
      order_ids:this.$route.params.order_ids,
      orders: [],
      orders_d: []
    };
    console.log(customer_id,orders_ids)
  },
  computed: {
    employee_id() {
      if (this.orders.length > 0) {
        const order_id = this.orders[0].id;
        return this.orders[0].employee_id;
      }
    },
    states() {
      return this.orders.map((elem) => elem.current_state);
    },
    // if the preparation is finished
    finished() {
      if (this.order_products.length > 0) {
        for (const order_product of this.order_products) {
          if (
            !order_product.ready &&
            order_product.distributed_quantity === null
          ) {
            return false;
          }
        }
      }
      if (this.orders_d.length > 0) {
        for (const order of this.orders_d) {
          if (!order.ready) return false;
        }
      }
      if (finished_modal_display) {
        this.show_finished_modal();
        finished_modal_display =false;
      }
      return true;
    },
    // if there lack of some product
    lack() {
      if (this.order_products.length > 0) {
        for (const order_product of this.order_products) {
          if (
            order_product.distributed_quantity > 0 &&
            order_product.distributed_quantity < order_product.quantity
          ) {
            return true;
          }
        }
      }
      return false;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loaded = false;
      axios.get("/customers/" + this.customer_id +"/"+ this.order_ids).then(({ data }) => {
        console.log(data);
        if (data.customer) this.customer = data.customer;
        if (data.order_products) {
          this.order_products = data.order_products;
          this.order_products = this.order_products.sort(function (a, b) {
            const categoriesOrder = ["Boissons", "Bien-être et maison", "Produits secs, en-cas et condiments", "Oeufs",
              "Légumes", "Fruits",  "Fruits et Légumes", "Viandes et charcuteries", "Poissons et produits du lac", "Produits laitiers et oeufs",
              "Plats cuisinés et pâtes fraiches", "Spécialités végétariennes et véganes", "Pains, chocolats et pâtisserie"];
            const categoryA = a.category ? a.category.name : "";
            const categoryB = b.category ? b.category.name : "";
            const indexA = categoriesOrder.indexOf(categoryA);
            const indexB = categoriesOrder.indexOf(categoryB);
            // Compare les catégories qui ne sont pas dans categoriesOrder
            if (indexA === -1 && indexB === -1) {
              return categoryA.localeCompare(categoryB);
            } else if (indexA === -1) {
              return 1;
            } else if (indexB === -1) {
              return -1;
            }
            // Compare les catégories dans categoriesOrder
            return indexA - indexB;
          });
        }
        if (data.products) this.products = data.products;
        if (data.orders) {
          this.orders = data.orders;
          if (this.orders.length > 0) {
            const order = this.orders[0];
            this.edit = order.employee_id == this.authUser.id;
          }
        }
        if (data.orders_d) {
          this.orders_d = data.orders_d;
        }
        this.loaded = true;
      });
    },
    
    toggleEdit() {
      this.edit = !this.edit;
      let value = this.edit ? this.authUser.id : null;
      console.log(value);
      for (const order of this.orders) {
        let order_id = order.id;
        axios
          .patch(`/orders/${order_id}`, {
            employee_id: value,
          })
          .then(({ data }) => {
            // data is the order object
            order.employee_id = data.employee_id;
          })
          .catch((error) => {
            console.log(error);
            if (error.response) console.log(error.response.data);
          });
      }
      // for (const order of this.orders_d) {
      //   console.log(order);
      //   let id = order.date_id;
      //   axios
      //     .patch(`/douzaine-date/${id}`, {
      //       employee_id: value,
      //     })
      //     .then(({ data }) => {
      //       // data is the order object
      //       console.log(data);
      //       order.employee_id = data.employee_id;
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       if (error.response) console.log(error.response.data);
      //     });
      // }
    },

    dz_image_asset(douzaine) {
      return (
        "https://www.vitaminelocale.ch/uploads/dz/" +
        douzaine.id +
        "/" +
        douzaine.image
      );
    },

    dz_thumb_asset(douzaine) {
      let image = douzaine.image.split(".");
      return (
        "https://www.vitaminelocale.ch/uploads/" +
        douzaine.id +
        "/" +
        image[0] +
        "-thumb." +
        image[1]
      );
    },
  },
};
</script>

<style></style>