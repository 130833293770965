<template>
    <div>
        <div class="text-right mb-4">
            <select :value="tour" @input="selectTour">
                <option v-for="item in tourOptions" :key="item" :value="item">
                    {{ item }}
                </option>
            </select>
        </div>
        <table class="table">
            <thead>
                <th>Tous les produits(catégorie(prod))</th>
                <th>unité</th>
                <th>Qté</th>
            </thead>
            <tr v-for="product in products" :key="product.id">
                <td>
                    <product-info :product="product" />
                </td>
                <td>
                    {{ product.unit }}
                </td>
                <td>
                    {{ getQuantity(product.id) }}
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            products:[],
            product_quantities:[],
        };
    },
    created(){
        this.fetch(); 
    },
    methods: {
        fetch() {
            let tour = this.tour;
            console.log(tour); 
            axios
                .get(`/tour/${tour}/ordered-products`)
                .then(({ data }) => {
                    // console.log(data);
                    if (data.product_quantities)                        
                        this.product_quantities = data.product_quantities;                         
                    if (data.products)
                        this.products = data.products;
                })
                .catch((error) => {
                    console.log(error); 
                });                
        },
        getQuantity(product_id) {
            const product_quantity = this.product_quantities.find(
                (elem) => elem.id_product == product_id
            );
            if (product_quantity) return product_quantity.total_quantity;
            return 0;
        },
        selectTour(e) {
            let tour = e.target.value;
            this.$store.commit("tourMutation", tour);
            this.fetch(); 
        },
    },
};
</script>

<style>
</style>