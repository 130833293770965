<template>
  <div class="form-group input-half-height">
    <label for="text-input" class="">REMARQUES SUR LA COMMANDE</label>
    <textarea v-model="note" @input="debouncedUpdate" class="form-control" placeholder="Entrez du texte..."></textarea>
  </div>
</template>


<script>
import { debounce } from 'lodash';
export default {
  props : ['orders'],
  data() {
    return {
      note : '',
      debouncedUpdate : debounce(this.update, 300)
    };
  },
  computed : {
    order_ids() {
      // Retourne uniquement les identifiants du tableau d'objets (this.orders)
      return this.orders.map(order => order.id);
    },
  },
  created() {
    // Récupération du premier élément orders (si plusieurs ou un seul) et conservation dans une variable state.
    this.note = this.orders[0].note ?? '';
  },
  methods: {
    async update() {
      const response = await axios.post('/order/note/update', {note : this.note, order_ids : this.order_ids});
    },
  },
};
</script>